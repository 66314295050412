<script setup lang="ts">
function titleTemplate(title: string | undefined): string {
  const fname = "ファミリー庭園";
  const _title = title ? title : "激安外構";

  if (_title.match(fname)) {
    return _title;
  }

  return `${_title} - ${fname}`;
}

useHead({
  htmlAttrs: {
    lang: "ja",
  },
  title: "ファミリー庭園",
  titleTemplate: titleTemplate,
  meta: [{ name: "description", content: "" }],
  link: [],
});
</script>

<template>
  <NuxtLoadingIndicator color="#01561f" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<style>
body {
  font-size: 16px;
  font-family: -apple-system, "BlinkMacSystemFont", "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", Meiryo, sans-serif, "Segoe UI Emoji";
}

p,
body,
ul {
  padding: 0;
  margin: 0;
}
i {
  font-style: normal;
}

/* MOverelay */
.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.overlay-enter-to .overlay-leave-from {
  opacity: 1;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: ease-in-out 200ms;
}

/* transitionで指定するアニメーション */

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/*  slide-fade-r */
.slide-fade-r-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-r-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-r-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slide-fade-r-enter-from {
  transform: translateX(20px);
  opacity: 0;
}
</style>
